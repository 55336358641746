import React from "react";

import { Container, Wrapper, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

const quotes = [
  {
    quote:
      "Just wanted to thank you and Bruce for your time today, you were both brilliant and really liked Bruce’s honest opinion about our options and his knowledge in regard to council and some issues we could face.   We have also taken on board Bruce’s opinion in regard to timelines and have spoken to our builder who has agreed to push back his start date to make more time for the geotechnical engineering and consent process, so thanks for that too.",
    client: "Graham and Karen",
    location: "York Bay, Wellington",
  },
];

const Clients = (props) => (
  <Layout>
    <Seo
      title="What our Clients Say about Us"
      pathname={props.location.pathname}
    />
    <Container>
      <Wrapper className="-textCenter">
        <h1>What our clients say about Us</h1>
        <Box className="-center" css={{ maxWidth: "750px" }}>
          {quotes.map((item, i) => {
            return (
              <Box key={i} gap={30}>
                <h3>"{item.quote}"</h3>
                <h4 className="-fontNormal">
                  <b>{item.client}</b>
                  <br />
                  {item.location}
                </h4>
              </Box>
            );
          })}
        </Box>
      </Wrapper>
    </Container>
  </Layout>
);

export default Clients;
